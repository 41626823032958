<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.cash_registers')">
            <div class="d-flex justify-content-end">
                <b-form-checkbox class="mr-2 my-auto h-full pt-0.5 disabled-items" switch v-model="showDisabled">{{ $t('config.cash_registers.show_disabled_cash_registers')}}</b-form-checkbox>

            </div>

            <!--<Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="cashRegisters" @edit="edit" />-->
            <Table v-if="arraysLoad" class="mt-1" :columnDefs="columnDefs" :rowData="cashRegisters" @edit="edit" />
        </b-card>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import {BCard, BFormCheckbox, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {CashRegisterType} from '@/libs/enums/CashRegisters'
    import {getLocaleObjectsCashRegister} from '@/libs/enums/Locales'
    import i18n from '@/i18n/i18n'

    export default {
        components: { BFormCheckbox, Table, BCard, BOverlay},
        computed: {
            localeObjects() {
                return getLocaleObjectsCashRegister()
            },
            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)

                return [
                    { headerName: this.$t('table_fields.cash_register_id'), field: 'cash_register_id', filter: true, editable: false},
                    { headerName: this.$t('table_fields.active_status'), field: 'status', editable: false, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: [{id: 1, name: this.$t('general.no')}, {id: 2, name: this.$t('general.yes')}]} }, cellRenderer: 'DisplayAvailabilityStatus', cellEditorFramework: 'selectEditor',  cellEditorParams: {values: [{id: 1, name: this.$t('general.no')}, {id: 2, name: this.$t('general.yes')}]} },
                    { headerName: this.$t('table_fields.device_type'), field: 'device_type', editable: false, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.deviceTypesFilter} }, cellRenderer: 'DisplayDeviceType'},
                    { headerName: this.$t('table_fields.business_unit'), field: 'business_unit_id', editable: false, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.business_units} }, cellEditorFramework: 'selectEditor', cellEditorParams: () => { return {values: this.business_units} }, cellRenderer: (params) => this.getBusinessUnitName(params.value)},
                    { headerName: this.$t('table_fields.printer_configs'), field: 'printer_id', editable: params => hasPermission && !params.data.disabled, filter: true, floatingFilterComponentParams: () => { return  {type: 'select', values: this.printers, multiple: false} }, cellEditorFramework: 'selectEditor', cellEditorParams: (x) => { return {values: this.getAllowedPrinters(x), multiple: false, selectValue: 'id', findValueInArray: true} }, cellRenderer: (params) => this.getPrinterName(params.value)},
                    { headerName: this.$t('table_fields.local_network'), field: 'local_network', editable: params => hasPermission && !params.data.disabled, filter: true, floatingFilterComponentParams: {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}], selectValue: 'value'}, cellRenderer: 'DisplayActive', cellEditorFramework: 'activeEditor' },
                    { headerName: this.$t('table_fields.enable_sync'), field: 'enable_sync', editable: params => hasPermission && !params.data.disabled, filter: true, floatingFilterComponentParams: {type: 'select', values: [{name: this.$t('general.yes'), value: true}, {name: this.$t('general.no'), value: false}], selectValue: 'value'}, cellRenderer: 'DisplayActive', cellEditorFramework: 'activeEditor' },
                    { headerName: this.$t('table_fields.cash_register_created_at'),
                      field: 'created_at',
                      editable: false,                      
                      filter: true,
                      floatingFilterComponentParams: {type: 'date'},
                      filterParams: {
                          textCustomComparator: this.$datesInRange
                      },
                      cellRenderer: 'DisplayDate'
                    },
                    { headerName: this.$t('menu.locales.language'),
                      field: 'language',
                      filter: true,
                      floatingFilterComponentParams: () => { return  {type: 'select', values: this.localeObjects, multiple: false} },
                      cellEditorFramework: 'selectEditor',
                      cellEditorParams: () => { return {values: this.localeObjects, multiple: false, selectValue: 'id',  findValueInArray: true} },
                      cellRenderer: 'DisplayLanguage',
                      minWidth: 150
                    }

                ]
            },
            cashRegisters() {
                const cashRegisters = this.cash_registers

                if (this.showDisabled) {
                    return cashRegisters
                }

                return cashRegisters.filter((x) => x.disabled === false)
            }
        },
        data() {
            return {
                cash_registers: [],
                showLoader: false,
                arraysLoad: false,
                business_units: [],
                printers:[],
                showDisabled: false,
                deviceTypesFilter: [
                    {
                        id: CashRegisterType.Windows,
                        name: `${i18n.t('config.display_device_type.windows')}`
                    },
                    {
                        id: CashRegisterType.MobileAndroid,
                        name: `${i18n.t('config.display_device_type.android_mobile')}`
                    },
                    {
                        id: CashRegisterType.DesktopAndroid,
                        name: `${i18n.t('config.display_device_type.android_desktop')}`
                    },
                    {
                        id: CashRegisterType.Unknown,
                        name: `${i18n.t('config.display_device_type.no_data')}`
                    }
                ]
            }
        },
        methods: {
            getAllowedPrinters(data) {
                if (!data.data) return this.printers
                return this.printers.filter(x => x.business_unit === data.data.business_unit_id)
            },
            async edit(params) {
                if (!params && params.data) {
                    return
                }
                const cashRegisterId = params.data.id

                try {
                    this.showLoader = true
                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/cash_registers/${cashRegisterId}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    if (err && err.response && err.response.data && (err.response.data.error === 'cash register id exists in business unit')) {
                        this.$printWarning(this.$t('print.warning.cash_register_id_already_exists'))
                    } else {
                        Sentry.captureException(err)
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            getPrinterName(value) {
                if (value) {
                    const item = this.printers.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },
            getBusinessUnitName(value) {
                if (value) {
                    const item = this.business_units.find(x => x.id === value)
                    if (item) return item.name
                }
                return '/'
            },

            async loadData() {
                try {
                    this.showLoader = true

                    const res1 = this.$http.get('/api/client/v1/cash_registers/?getAll=true')
                    const res2 = this.$http.get('/api/client/v1/business_units/')
                    const res3 = this.$http.get('/api/client/v1/printer_config/')

                    const responses = await Promise.all([res1, res2, res3])

                    this.cash_registers = responses[0].data.items ?? []
                    this.business_units = responses[1].data ?? []
                    this.printers = responses[2].data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
            this.arraysLoad = true
        }
    }
</script>

<style scoped>

</style>
